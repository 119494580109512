import { evaluateMathOperation } from '../../lib/utils';
import { isEmpty } from '../../lib/utils';

// look up and evaluate the given matrix
const MatrixLookup = (matrixName, answers, matrixData) => {
    // Note: This component, being called conditionally, should not use hooks
    // like useState and useContext etc. to maintain react hooks order.
    
    const matrixRec = matrixData.filter(
        (x) => x.matrix.matrixName === matrixName
    );
    if (matrixRec.length !== 1) {
        throw new Error(`matrix ${matrixName} not found`);
    }
    
    const header = matrixRec[0].matrix;
    const details = matrixRec[0].matrixDetails;
    const matrixVars = [
        { index: 1, variable: header.variable1, operator: header.varOp1 },
        { index: 2, variable: header.variable2, operator: header.varOp2 },
        { index: 3, variable: header.variable3, operator: header.varOp3 },
        { index: 4, variable: header.variable4, operator: header.varOp4 },
        { index: 5, variable: header.variable5, operator: header.varOp5 },
    ];

    var matrixMatchCounter = 0;
    //if matrix variable present but answer is not given by user, return null
    for (let obj of matrixVars) {

        if ((obj.variable !== null && !isEmpty(obj.variable.trim()))  && !answers[obj.variable]) 
            matrixMatchCounter++;
        else if(obj.variable === null || isEmpty(obj.variable.trim()))
            matrixMatchCounter++;
    }
    if(matrixMatchCounter===matrixVars.length)
        return null; //no matrix records that match

    //evaluate the value for this matix. 
    // Logic: Starting with variable1, filter the rows that satisfy condition given for variable1.
    // Use the filtered rows as base set to evaluate condition given for variable2, and so on..
    // If zero rows are returned as the result of filter operation for any variable, 
    // then look for the default row  (i.e. keyValue === '*')
    let filteredRows = details;
    for (let obj of matrixVars) {
        if (obj.variable === null || isEmpty(obj.variable.trim())) continue;  // continue the loop if variable is null
        else {
            // filter out matching rows for this condition
            let result = filteredRows.filter((row) =>
                evaluateMathOperation(
                    answers[obj.variable],
                    row[`keyValue${obj.index}`],
                    obj.operator
                )
            );
            
            // if no matching rows found, look for default rows 
            if (result.length === 0) {
                // filter default rows with variable value = "*"
                result = filteredRows.filter((row) =>
                    evaluateMathOperation(
                        '*',
                        row[`keyValue${obj.index}`],
                        obj.operator
                    )
                );
            }
            // use this result as base set for the next variable to be evaluated
            filteredRows = result;
            //if(result.length > 0)      break;
            
            
        }
    }

    // at the end of the loop, we should have a single row as a result of the lookup.
    // if no row found, return 0. If multiple rows found, throw error
    if (filteredRows.length === 0) return 0;
    if (filteredRows.length > 1)
        throw new Error(
            `${filteredRows.length} rows found in matrix lookup for name ${matrixName}. Only one expected.`
        );

    //return the matrix evaluation result
    return filteredRows[0].rtnValue;
};

export default MatrixLookup;
