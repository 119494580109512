import { evaluateMathOperation } from '../../lib/utils';
import MatrixLookup from './MatrixLookup';

/// evaluate given condition records
const EvaluateCondition = (conditionRecords, answers, matrixData, configModel) => {
    
    answers["IV_CONFIG_GRP"] = configModel.replace("PG","CG");
    answers["BUSINESS_UNIT_IN"] = answers["BUSINESS_UNIT_IN"].replace("002-","");
    // Note: This component, being called conditionally, should not use hooks
    // like useState and useContext etc. to maintain react hooks order. 
    if (conditionRecords === null || conditionRecords === undefined || conditionRecords.length === 0)
    {
        
        return false;
        //throw new Error('No conditions given for evaluation');
    }
     
    for(let i=0;i< conditionRecords.length; i++){
      
        let { condOperator, conditionVariable, conditionAnswer, conditionCompOperator, conditionName } = conditionRecords[i];
        if(conditionName === "CND_SA_MTO_EXCL")
            debugger;

      
            const leftOperand =
                conditionRecords[i].matrix === true
                ? MatrixLookup(conditionVariable, answers, matrixData)
                : (conditionVariable === "1" ? "1" : answers[conditionVariable]);

            const rightOperand = (conditionAnswer === "1" ? "1" : conditionAnswer);
            
            conditionRecords[i]["Result"] =  (leftOperand 
                && evaluateMathOperation(
                    leftOperand,
                    rightOperand,
                    conditionCompOperator
                )
            )  
               
              
            
        

    }
    
  

    // Start with the result of the first object
    let result = conditionRecords[0].Result;
    
    for (let i = 0; i < conditionRecords.length - 1; i++) {
        const { conditionOperator } = conditionRecords[i];
        const nextResult = conditionRecords[i + 1].Result;

        if (conditionOperator === "AND") {
            result = result && nextResult;
        } else if (conditionOperator === "OR") {
            result = result || nextResult;
        } else if (conditionOperator === "") {
            // Skip if the operator is empty (last item)
            continue;
        } else {
            console.warn(`Unknown CondOperator: ${conditionOperator}`);
        }
    }


    return result;
        
    
};

export default EvaluateCondition;
